html {
    font-size: 16px;
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
}

#root,
.App {
    height: 100%;
}

.gradient::before {
    content: '';
    width: 100%;
    height: 600px;
    background: rgb(198, 200, 255);
    /* background-image: linear-gradient(-225deg, #7DE2FC 0%, #B9B6E5 100%); */
    background: linear-gradient(90deg, rgba(198, 200, 255, 1) 0%, rgba(254, 205, 233, 1) 45%, rgba(255, 233, 234, 1) 77%);
    position: fixed;
    z-index: -100;
    left: 0;
    top: -100px;
    filter: blur(100px);
}

.MuiButton-label h2,
.MuiButton-label h3,
.MuiButton-label h4,
.MuiButton-label h5,
.MuiButton-label h6,
h3.MuiPickerDTToolbar-separator {
    font-family: "Open Sans", sans-serif;
}

.MuiLinearProgress-root {
    position: fixed !important;
    width: 100%;
    top: 56px;
    left: 0;
}

hr.small {
    border-top: 2px solid #00bcd4;
    width: 40px;
    margin: 0 0 15px 0;
}

hr.small.center {
    margin: 0 auto 15px auto;
}

.fadeIn-appear {
    opacity: 0.01;
    top: 25px;
    position: relative;
}

.fadeIn-appear.fadeIn-appear-active {
    opacity: 1;
    top: 0;
    transition: all .5s ease 0s;
}

.fadeIn-leave {
    opacity: 1;
}

.fadeIn-leave.fadeIn-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.centered-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: .5em;
    padding-bottom: 1em;
}

.user-status {
    position: relative;
}

.user-status .status-indc {
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 20px;
    top: 2px;
    right: 4px;
    border: 2px solid white;
    z-index: 10;
}

.user-status .isoffline {
    background: #C5C5C5;
}

.user-status .isonline {
    background: #00D100;
    background: #04d1dc;
}

.fc-today {
    background: #f1eefd !important;
}

.fc-event,
.fc-event-dot {
    background-color: #9371f6;
}

.fc-event {
    border: 1px solid #9371f6;
}

.MuiDialogTitle-root h6 {
    font-weight: bold;
    /* font-family: urbane-rounded; */
    font-family: Paytone One, sans-serif;
}

.heading-font {
    font-family: Paytone One, sans-serif !important;
}

.MuiDialogActions-root button {
    font-weight: 600;
}

.MuiDialogActions-root.actionContainer {
    padding: 20px;
}

.MuiPickersToolbarText-toolbarTxt.MuiTypography-h3 {
    font-size: 2.7849rem;
}

.MuiButton-containedPrimary {
    font-weight: bold !important;
}

.MuiButton-root.btn-danger {
    color: #F6636B;
}

.MuiButton-outlined.btn-danger {
    border-color: #F6636B;
}

.MuiButton-contained.btn-danger {
    background: #F6636B;
    color: #ffffff;
}

.MuiButton-contained.btn-danger:hover {
    background: #DD5A56;
    border-color: #D36561;
    color: #ffffff;
}

.MuiDialog-root .alertIcon {
    width: 100%;
    font-size: 45px;
    color: #767676;
    margin-bottom: 15px;
}

.MuiFab-root.floating-bottom-right {
    position: fixed;
    bottom: 25px;
    right: 20px;
    z-index: 100;
}

.MuiIconButton-root.row-icons {
    color: #c5c5c5;
}

.MuiIconButton-root.row-icons:hover {
    color: #606060;
}

.MuiTableRow-head th {
    font-weight: 900;
}

.button-loading {
    color: #fff !important;
    margin-right: 10px !important;
}

.MuiTabs-flexContainerVertical .MuiTab-wrapper {
    text-align: left !important;
    align-items: flex-start !important;
    padding: 10px;
}

.MuiTabs-flexContainerVertical .MuiTab-wrapper .MuiSvgIcon-root {
    margin-right: 15px;
    vertical-align: middle;
}

.centervh {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.MuiAlert-standardWarning {
    border-left: 5px solid #ff9800;
}

.MuiAlert-standardError {
    border-left: 5px solid #F6636B;
}

.MuiAlert-standardInfo {
    border-left: 5px solid #8F77EE;
    background: #EAE7FF !important;
    color: #8F77EE !important
}

.MuiAlert-standardInfo .MuiAlert-icon {
    color: #8F77EE !important
}

.MuiAlert-standardSuccess {
    border-left: 5px solid #00cfb2;
    background: #D4F8F3 !important;
    color: #03917d !important;
}

.MuiAlert-standardSuccess .MuiAlert-icon {
    color: #00cfb2 !important;
}

#availability-builder .fc-day:hover {
    content: " ";
    width: 99.9%;
    height: 99.9%;
    display: block;
    border: 2px double #bda9f7;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.fc-event {
    cursor: pointer;
}

.fc-slats,
.fc-content-skeleton,
.fc-bgevent-skeleton {
    pointer-events: auto;
}

.fc-bgevent,
.fc-event-container {
    pointer-events: auto;
}

.tooltip {
    position: absolute;
    z-index: 10000;
    background: white;
    pointer-events: none;
}

.day-number {
    font-size: 13px;
    color: #616161;
}

.fc-content-skeleton td {
    padding: 1px !important;
}

#availability-builder .fc-content {
    padding: 5px !important;
}

#availability-builder .fc-past {
    background: #F6F6F6 !important;
}

#availability-builder .fc-event.unavailable {
    border-style: dashed;
}

#availability-builder .fc-event.recurring {
    position: relative;
}

.centered {
    text-align: center;
    margin: 0 auto;
}


/* #availability-builder .fc-event.recurring::before {
    content: "▸";
    position: absolute;
    top: -11px;
    font-size: 15px;
    right: 4%;
    padding-right: 5px;
    color: #dddddd;
    background: white;
}

#availability-builder .fc-event.recurring::after {
    content: "◂";
    position: absolute;
    bottom: -9px;
    font-size: 15px;
    left: 5%;
    color: #dddddd;
    padding-left: 5px;
    background: white;
} */

#availability-builder .fc-today {}

.cancelled-schedule-item,
.MuiChip-root.cancelled-schedule-item {
    background: #F6636B !important;
    border-color: #D36561 !important;
    color: #ffffff;
}

.fc-event.cancelled-schedule-item {
    background: #ffacb0 !important;
    color: #db2506 !important;
}

.fc-event.cancelled-schedule-item .fc-content {}

.fc-event.cancelled-schedule-item .fc-time::before {
    content: "✗ ";
    margin-left: 2px;
}

.MuiPaper-elevation1 {
    box-shadow: rgb(36 37 38 / 8%) 0px 5px 6px !important;
}

.fc-day-top {
    font-size: clamp(18px, 2.5vw, 24px);
}

.fc-day-number {
    padding: 10px;
}


/* Small devices (phones) */

@media only screen and (max-width: 600px) {
    #main-calendar td.fc-event-container:after {
        content: '•';
        position: absolute;
        top: 0;
        left: 50%;
        color: #9572f6;
        font-size: 40px;
        visibility: visible;
        line-height: 0;
    }
    #main-calendar td.fc-event-container {
        position: relative;
    }
    #main-calendar .fc-day-grid-event {
        display: none !important;
    }
    #main-calendar .fc-unthemed .fc-content,
    #main-calendar .fc-unthemed .fc-divider,
    #main-calendar .fc-unthemed .fc-list-heading td,
    #main-calendar .fc-unthemed .fc-list-view,
    #main-calendar .fc-unthemed .fc-popover,
    #main-calendar .fc-unthemed .fc-row,
    #main-calendar .fc-unthemed tbody,
    #main-calendar .fc-unthemed td,
    #main-calendar .fc-unthemed th,
    #main-calendar .fc-unthemed thead {
        border: none !important;
    }
    #main-calendar .fc th {
        text-align: left !important;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) {
    .MuiLinearProgress-root {
        top: 0;
    }
}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {
    .centered-container {
        padding: 1em
    }
}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
    .centered-container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 2em
    }
}